import React from 'react'
import { ThemeProvider } from '@mui/styles'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let theme = createTheme({
    breakpoints: {
        values: {
          xxs: 0,
          xs:375,
          sm: 768,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
})
theme = createTheme(theme, {
    palette:{
        primary:{
            main:'#3a1798',
            // main:'#3e6990',
            light:'#ea31a2',
            dark:"rgba(62, 105, 144, 0.25)",
            contrastText:'#87e182'
        },
        secondary:{
            main:'#E2DCDE',
            dark:'#cbc6c8',
            contrastText:'#19b997'
        },
        gradients:{
            frenchViolet:'#7911BC',
            frostbite:'#EA31A2',
            frostbiteHalf:'#80EA31A2',
            dodgerBlue:'#4798F3',
            zaffre:'#3A1798',
            lightGreen:'#87E182',
            mountainMeadow:'#19B997',
            mountainMeadowDark:'#126e5a',
            deepChampagne:'#F6D8AE',
            lavenderBlush:'#F0E6EF',
            raisinblack:'#231F20',
            blastOffBronze:'#B27C66',
            richBlack:'#080708',
            darkorange:'#FB8B24'

        },
        gradientsLightOpacity:{
            frenchViolet:'#E67911BC',
            frostbite:'#E6EA31A2',
            dodgerBlue:'E6#4798F3',
            zaffre:'#E63A1798',
            lightGreen:'#E687E182',
            mountainMeadow:'#E619B997',
            mountainMeadowDark:'#E6126e5a',
            deepChampagne:'#E6F6D8AE',
            lavenderBlush:'#E6F0E6EF',
            raisinblack:'#E6231F20',
            blastOffBronze:'#E6B27C66',
            richBlack:'#E6080708',
            darkorange:'#E6FB8B24'
        },
        gunslinger:{
            darkest:'#451411',
            secondDarkest:'#7d2d18',
            thirdDarkest:'#bb4826',
            fourthDarkest:'#d45227',
            sky:'#ed7a25',
            sun:'#ffdd8c',
            haze:'#eea225',
            cloud:'#f5c329',
            cloud2:'#ffedbf',
            tower:'#3e0403'
        },
        textPrimary:{
            main:'#000000'
        },
        textSecondary:{
            main:'#0F376C'
        },
    },
})

theme.typography.h5 = {
    fontSize:'1.5rem',
    fontFamily:'system-ui',
    '@media (max-width:960px)':{
        fontSize:'1.25rem'
    },
    '@media (max-width:600px)':{
        fontSize:'1.15rem'
    }
}
theme.typography.h4 = {
    fontSize:'1.9rem',
    fontFamily:'system-ui',
    '@media (max-width:960px)':{
        fontSize:'1.5rem'
    },
    '@media (max-width:600px)':{
        fontSize:'1.25rem'
    }
}
theme.typography.h3 = {
    fontSize:'1.75rem',
    fontFamily:'system-ui',
    '@media (max-width:1280px)':{
        fontSize:'1.5rem'
    },
    '@media (max-width:960px)':{
        fontSize:'1.25rem'
    },
    '@media (max-width:600px)':{
        fontSize:'1rem'
    }
}
theme.typography.h2 = {
    fontSize:'2rem',
    padding:"20px",
    fontFamily:'system-ui',
    '@media (max-width:1280px)':{
        fontSize:'1.75rem'
    },
    '@media (max-width:960px)':{
        fontSize:'1.5rem'
    },
    '@media (max-width:600px)':{
        fontSize:'1.25rem'
    }
}
theme.typography.h1 = {
    fontSize:'2.25rem',
    padding:"25px",
    fontFamily:'Holtwood One SC, serif',
    '@media (max-width:1280px)':{
        fontSize:'2rem'
    },
    '@media (max-width:960px)':{
        fontSize:'1.75rem'
    },
    '@media (max-width:600px)':{
        fontSize:'1.5rem'
    }
}
theme.typography.body2 = {
    fontSize:'1.25rem',
    color:theme.palette.gradients.raisinblack,
    fontFamily:'Rubik',
    '@media (max-width:960px)':{
        fontSize:'1.25rem'
    },
    '@media (max-width:600px)':{
        fontSize:'.8rem'
    }
}
theme.scrollbar = {
    vertical:{
        '&::-webkit-scrollbar': {
            height:'10px'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.gradients.zaffre,
            background:`linear-gradient(90deg, ${theme.palette.gradients.frostbite},${theme.palette.gradients.frenchViolet})`,
            borderRadius:'10px',
          },
    },
    horizontal:{
        '&::-webkit-scrollbar': {
            height:'10px'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.gradients.zaffre,
            background:`linear-gradient(${theme.palette.gradients.frostbite},${theme.palette.gradients.frenchViolet})`,
            borderRadius:'10px',
          },
    }
}
theme.button1 = {
    ["::before"]: {
        content: '""',
        backgroundColor: `black`,
        backgroundImage: `linear-gradient(90deg, transparent 50%, ${theme.palette.gradients.frostbite} 100%)`,
        border: `2px solid ${theme.palette.gradients.frostbite}`,
        borderBottom: "none",
        borderLeft: "none",
        position: "absolute",
        top: "-10px",
        bottom: "-10px",
        right: "-10px",
        left: "-10px",
        zIndex: -1,
      },
      ["::after"]: {
        content: '""',
        background:`linear-gradient(0deg,${theme.palette.gradients.lightGreen} 0%, transparent 50%)`,
        border: `2px solid ${theme.palette.gradients.lightGreen}`,
        borderRight: "none",
        borderTop: "none",
        position: "absolute",
        top: "-10px",
        bottom: "-10px",
        right: "-10px",
        left: "-10px",
        zIndex: -1,
      },
}
theme.button1Inner = {
    ["::before"]: {
        content: '""',
        border: `2px solid ${theme.palette.gradients.frostbite}`,
        borderBottom: "none",
        borderLeft: "none",
        position: "absolute",
        top: "-2px",
        bottom: "-2px",
        right: "-2px",
        left: "-2px",
        zIndex: -1,
      },
      ["::after"]: {
        content: '""',
        border: `2px solid ${theme.palette.gradients.lightGreen}`,
        borderRight: "none",
        borderTop: "none",
        position: "absolute",
        top: "-2px",
        bottom: "-2px",
        right: "-2px",
        left: "-2px",
        zIndex: -1,
      },
}
export const wrapRootElement = ({ element }) => 
    <ThemeProvider theme={theme}>
        {element}
    </ThemeProvider>;